* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  z-index: 10;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
  height: 75px;
  line-height: 75px;
  background: rgb(17, 24, 39);
}

nav .navbar-brand {
  color: #ffffff;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: .5px;
}

nav .nav-list {
  display: flex;
}

nav .nav-list .nav-item {
  cursor: pointer;
  height: 100%;
  padding: 0 30px;
  transition: .4s;
}

nav .nav-list .nav-item:hover {
  background: rgb(99 102 241);
}

nav .nav-list .nav-link {
  color: #f1f1f1;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 935px) {

  nav {
      padding: 0 2rem;
  }

  nav .nav-list .nav-item {
      padding: 0 20px;
  }

}

@media (max-width: 675px) {

  nav .nav-list{
      display: grid;
      position: absolute;
      background: rgb(99 102 241);
      width: 100%;
      top: 75px;
      left: 0;
      transform: translateY(-100%);
      z-index: -1;
      transition: .6s;
  }

  nav .nav-list .nav-item {
      padding-left: 35px;
  }

  nav .navbar-toggler {
      display: grid !important;
  }
}

nav .navbar-toggler {
  display: none;
  height: min-content;
  margin: 18px -10px 0 0;
  cursor: pointer;
}

nav .navbar-toggler .bar {
  width: 40px;
  height: 5px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 10%;
  transition: .4s;
}

#toggle {
  display: none;
}

#toggle:checked ~ nav .nav-list {
  transform: translateY(0);
  z-index: 12;
}

#toggle:checked ~ nav .navbar-toggler {
  margin-top: 35px;
}

#toggle:checked ~ nav .navbar-toggler .bar:nth-child(1) {
  position: absolute;
  transform: rotate(45deg);
}

#toggle:checked ~ nav .navbar-toggler .bar:nth-child(2) {
  display: none;
}

#toggle:checked ~ nav .navbar-toggler .bar:nth-child(3) {
  transform: rotate(-45deg);
}