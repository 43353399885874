.addedToCart{
    position: fixed;
    z-index: 20;
}
.addedToCart span{
    background-color: rgb(108, 255, 152);
    z-index: 20;
    transform: translate3D(40vw,-38vh,0);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border-left: 5px solid rgb(5, 201, 5);
    border-radius: 5px;
}

.addedToCart.show{
    animation: showAlert 0.3s ease forwards;
}

@keyframes showAlert {
    0%{
        display: block;
        transform:translate3D(0,-80vh,0);
    }
    40%{
        display: block;
        transform: translate3D(0,38vh,0);
    }
    70%{
        display: block;
        transform: translate3D(0,35vh,0);
    }
    100%{
        display: block;
        transform: translate3D(0,35vh,0);
    }
}